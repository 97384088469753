import React, { Component } from "react";
import { connect } from 'react-redux';
import Select from 'react-select';

import _ from 'i18n';
import { setEventsFilters } from 'actions';
import { fetchPartnerEvents, fetchCouponModels } from 'thunks';
import TTPSelect from "common/TTPSelect";
import { EVENT_VISITOR_TYPES } from "constants"
import {
  sortEventByStartDateTime, transformDateFormat,
  ucFirst
} from "../../../../../services/common";

const mapStateToProps = (store, ownProps) => ({
    eventFilters: store.currentCampaign.email.eventFilters,
    events: store.filters.events.items,
    couponModels: store.filters.couponModels.items,
    currentClient: store.auth.currentClient,
    language: store.params.lng,
   campaignLanguage: store.currentCampaign.email.language
});

const mapDispatchToProps = dispatch => ({
    handleEventChange: (eventId) => dispatch(setEventsFilters({ property: 'event.id', operator: 'eq', value: eventId })),
    handlePartnerChange: (partnerId) => dispatch(setEventsFilters({ property: 'partner.id', operator: 'eq', value: partnerId })),
    handleOrganizationChange: (organizationId) => dispatch(setEventsFilters({ property: 'partner.organization', operator: 'eq', value: organizationId })),
    handleVisitorTypeChange: (types) => dispatch(setEventsFilters({ property: 'visitorType', operator: 'in', value: (types && types.length > 0) ? types : "" })),
    fetchPartnerEvents: () => dispatch(fetchPartnerEvents()),
    handleInvitationTypeChange: (types) => dispatch(setEventsFilters({ property: 'couponModel.id', operator: 'in', value: (types && types.length > 0) ? types : "" })),
    fetchCouponModels: (event) => dispatch(fetchCouponModels(event)),
});
@connect(mapStateToProps, mapDispatchToProps)
export default class PartnerGuestFilters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      eventsType: 'future',
    };
  }

  componentDidMount() {
        const { fetchCouponModels, eventFilters, fetchPartnerEvents } = this.props;
        let filters = [];
        fetchPartnerEvents();
        (eventFilters || []).forEach(filter => {
            filters[filter.property] = filter.value;
        });
        if (filters && filters["event.id"]) {
            fetchCouponModels(filters["event.id"]);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { handleOrganizationChange, eventFilters, currentClient } = nextProps;
        if (eventFilters.length == 0 && currentClient && currentClient.id) {
            handleOrganizationChange(currentClient.id);
        }
    }

    handleEventChange = ({ eventId, partnerId }) => {
        const { handleEventChange, fetchCouponModels, handleInvitationTypeChange, handlePartnerChange } = this.props;
        handleEventChange(eventId);
        handlePartnerChange(partnerId);

        //init event related filters
        handleInvitationTypeChange([]);

        if (eventId) {
            fetchCouponModels(eventId);
        }
    }

    render() {
      const { events, language, handleVisitorTypeChange, eventFilters, handleInvitationTypeChange, couponModels, campaignLanguage } = this.props;
      const { eventsType } = this.state;

        let filters = [];
        (eventFilters || []).forEach(filter => {
            filters[filter.property] = filter.value;
        });

        let selectedEvent = filters['event.id'] || null;

        let selectedVisitorType = filters['visitorType'] || null;
        let selectedInvitationType = filters['couponModel.id'] || null;

        let lng = ucFirst(language);
        let campaignLng = ucFirst(campaignLanguage);

      let sortedEvents = sortEventByStartDateTime(events, 'DESC', eventsType)
      let eventOptions = sortedEvents?.map(event => {
        const getEventName = () => {
          if (campaignLng !== 'All' && event[`name${campaignLng}`]) {
            return event[`name${campaignLng}`];
          }
          // Fallback order: Fr -> Nl -> En
          return event['nameFr'] || event['nameNl'] || event['nameEn'] || 'Unnamed Event';
        };

        const eventName = getEventName();
        const eventStartDate = transformDateFormat(event['startDateTime']);

        return {
          label: `${eventStartDate} || ${eventName}`,
          id: event.id.toString()
        };
      });

        let invitationTypeOptions = couponModels.map(couponModel => {
            let label = couponModel[`modelName${lng}`] || couponModel['modelNameFr'];
            return { label, id: couponModel.id + "" };
        });

        return (
            <div className="row align-center">
                <div className="columns small-6 columns-padding">
                    <div className="sidebar__form-control">
                      <h1 className="param__title">{_('event')}
                        <span style={{float: "right"}}>
                          <span style={{marginLeft: '10px'}}>
                            <input type="radio" name='future' style={{margin: 'unset'}} defaultChecked={true} checked={this.state.eventsType === 'future'}
                                   onChange={() => this.setState({...this.state, eventsType: 'future'})}/>
                            <span className="ttp-radio"></span>
                            <span style={{marginLeft: '5px'}}>{_('future')}</span>
                          </span>
                          <span style={{margin: '0 10px 0 10px '}}>
                          <input type="radio" name='replay' style={{margin: 'unset'}} defaultChecked={false} checked={this.state.eventsType === 'past'}
                                  onChange={() => this.setState({...this.state, eventsType: 'past'})}/>
                          <span className="ttp-radio"></span>
                          <span style={{marginLeft: '5px'}}>{_('past')}</span>
                          </span>
                        </span>
                      </h1>
                      <Select
                        isMulti={false}
                        isClearable={false}
                        isSearchable
                        value={eventOptions.filter(({eventId}) => eventId == selectedEvent)}
                        placeholder={_('Select event')}
                        onChange={this.handleEventChange}
                        options={eventOptions}
                        className={`ttp-select`}
                        classNamePrefix="ttp-select"
                      />
                    </div>
                </div>
              <div className="columns small-6 columns-padding">
                <div className="sidebar__form-control">
                  <h1 className="param__title">{_('invitation type')}</h1>
                  <TTPSelect
                    values={selectedInvitationType}
                    placeholder={_('Select invitation type')}
                    options={invitationTypeOptions}
                    onChange={handleInvitationTypeChange}/>
                </div>
              </div>
              <div className="columns small-6 columns-padding">
                <div className="sidebar__form-control">
                        <h1 className="param__title">{_('visitor type')}</h1>
                        <TTPSelect
                            values={selectedVisitorType}
                            placeholder={_('Select visitor type')}
                            options={EVENT_VISITOR_TYPES.map(({ id, label }) => ({ id, label: _(label).toUpperCase() }))}
                            onChange={handleVisitorTypeChange} />
                    </div>
                </div>
                <div className="columns small-6 columns-padding">

                </div>
            </div>
        );
    }
}
