import _ from 'i18n';

export const CAMPAIGN_DYNAMIC_FIELDS = {
  RECIPIENT: [
    {
      code: "{{RECIPIENT.LN}}",
      title: { fr: "nom", nl: "achternaam", en: "last name" }
    },
    {
      code: "{{RECIPIENT.FN}}",
      title: { fr: "prénom", nl: "Voornaam", en: "first name" }
    },
    {
      code: "{{RECIPIENT.EMAIL}}",
      title: { fr: "email", nl: "email", en: "email" }
    },
    {
      code: "{{USER.BARCODE}}",
      title: {fr: "BARCODE D'UTILISATEUR", nl: "GEBRUIKERS STREEPJESCODE", en: "USER BARCODE"}
    }
],
  PAYMENT: [
    {
      code: "{{DOCUMENT.AMOUNT}}",
      title: { fr: "montant", nl: "bedrag ", en: "amount" }
    },
    {
      code: "{{DOCUMENT.COMMENT}}",
      title: { fr: "commentaire", nl: "commentaar", en: "comment" }
    },
    {
      code: "{{DOCUMENT.OFFICIAL_NUMBER}}",
      title: {
        fr: "numéro officiel",
        nl: "officieel nummer",
        en: "official number"
      }
    },
    {
      code: "{{DOCUMENT.LINK}}",
      title: {
        fr: "lien du document",
        nl: "document link",
        en: "document link"
      }
    },
    {
      code: "{{DOCUMENT.APP_TYPE}}",
      title: {
        fr: "type d'inscription",
        nl: "registratie type",
        en: "registration type"
      }
    },
    // ],
    // PAYMENT_RELANCE: [
    {
      code: "{{REMINDER.USER_NAME}}",
      title: { fr: "Nom", nl: "Naam", en: "Name" }
    },
    {
      code: "{{REMINDER.EVENT_NAME}}",
      title: {
        fr: "Nom de l'événement ",
        nl: "Evenement naam",
        en: "Event name"
      }
    },
    {
      code: "{{REMINDER.EVENT_DATE}}",
      title: {
        fr: "Date de l'événement",
        nl: "Evenement datum",
        en: "Event Date"
      }
    },
    {
      code: "{{REMINDER.LINK}}",
      title: {
        fr: "Lien de l'inscription",
        nl: "Registratielink",
        en: "Registration link"
      }
    },
    {
      code: "{{REMINDER.APP_TYPE}}",
      title: {
        fr: "Type d'inscription",
        nl: "Registratie type",
        en: "Registration type"
      }
    },
    {
      code: "{{SUBSCRIPTION.LINK}}",
      title: {
        fr: "Lien de l'abonnement",
        nl: "Abonnementslink",
        en: "Subscription link"
      }
    }
  ],
  SURVEY: [
    {
      code: "{{SURVEY.TITLE}}",
      title: {
        fr: "titre de l'enquête",
        nl: "titel van de enquête",
        en: "survey title"
      }
    },
    {
      code: "{{SURVEY.URL}}",
      title: { fr: "url de l'enquête", nl: "survey url", en: "survey url" }
    }
  ],
  UNITED_ASSOCIATES: [
    {
      code: "{{UA.MAIN_LINK}}",
      title: { fr: "lien principal", nl: "hoofdlink", en: "main link" }
    },
    {
      code: "{{UA.ACCEPT_INVITATION_LINK}}",
      title: {
        fr: "lien d'acceptation de l'invitation",
        nl: "uitnodiging accepteren link",
        en: "invitation accept link"
      }
    },
    {
      code: "{{UA.REJECT_INVITATION_LINK}}",
      title: {
        fr: "lien de refus de l'invitation",
        nl: "uitnodiging afwijzen link",
        en: "invitation reject link"
      }
    },
    {
      code: "{{UA.FIDUCIARY_NAME}}",
      title: {
        fr: "nom fiduciaire",
        nl: "vertrouwenspersoon",
        en: "fiduciary name"
      }
    },
    {
      code: "{{UA.CREATE_NEWSLETTER_LINK}}",
      title: {
        fr: "Lien de création de la newsletter",
        nl: "Link voor het maken van de nieuwsbrief",
        en: "Link to create the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_OBJECT}}",
      title: {
        fr: "Objet de la newsletter",
        nl: "Onderwerp nieuwsbrief",
        en: "Newsletter object"
      }
    },
    {
      code: "{{UA.NEWSLETTER_SENDER}}",
      title: {
        fr: "Expéditeur de la newsletter",
        nl: "Afzender van de nieuwsbrief",
        en: "Sender of the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_SENDER_NAME}}",
      title: {
        fr: "Nom d'expéditeur de la newsletter",
        nl: "Afzender van de nieuwsbrief",
        en: "Sender name of the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_LANGUAGE}}",
      title: {
        fr: "Langue de la newsletter",
        nl: "Taal van de nieuwsbrief",
        en: "Language of the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_RECIPIENT}}",
      title: {
        fr: "Destinataire de la newsletter",
        nl: "Ontvanger van de nieuwsbrief",
        en: "Recipient of the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_SENT_DATE}}",
      title: {
        fr: "Date d'envoie",
        nl: "Datum verzending",
        en: "Date of sending"
      }
    },
    {
      code: "{{UA.NEWSLETTER_SENT_TIME}}",
      title: {
        fr: "Temps d'envoie",
        nl: "Tijd van verzending",
        en: "Sending time"
      }
    }
  ],
  GDPR: [
    {
      code: "{{OPT_IN_URL}}",
      title: { fr: "url d'opt in", nl: "opt in url", en: "opt in url" }
    },
    {
      code: "{{OPT_OUT_URL}}",
      title: { fr: "url d'opt out", nl: "opt out url", en: "opt out url" }
    }
  ]
};

export const DYNAMIC_FIELDS = [
  { label: _('sender_name') + " : {{ SENDER.NAME }}", value: "{{ SENDER.NAME }}" },
  { label: _('guest_barcode') + " : {{ GUEST.BARCODE }}", value: "{{ GUEST.BARCODE }}" },
  { label: _('recipient_user_barcode') + " : {{ USER.BARCODE }}", value: "{{ USER.BARCODE }}" },
  { label: _('event_id') + " : {{ EVENT.ID }}", value: "{{ EVENT.ID }}" },
  { label: _('event_banner') + " : {{ EVENT.BANNER }}", value: "{{ EVENT.BANNER }}" },
  { label: _('event_name') + " : {{ EVENT.NAME }}", value: "{{ EVENT.NAME }}" },
  { label: _('event_date') + " : {{ EVENT.DATE }}", value: "{{ EVENT.DATE }}" },
  { label: _('event_start_time') + " : {{ EVENT.START_DATE_TIME }}", value: "{{ EVENT.START_DATE_TIME }}" },
  { label: _('event_end_time') + " : {{ EVENT.END_DATE_TIME }}", value: "{{ EVENT.END_DATE_TIME }}" },
  { label: _('event_place') + " : {{ EVENT.PLACE }}", value: "{{ EVENT.PLACE }}" },
  { label: _('event_user_documents_url') + " : {{ EVENT.USER_DOCUMENTS_URL }}", value: "{{ EVENT.USER_DOCUMENTS_URL }}" },
  { label: _('event_offfcourse_home_url') + " : {{ EVENT.OFFFCOURSE_HOME_URL }}", value: "{{ EVENT.OFFFCOURSE_HOME_URL }}" },
  { label: _('event_contact_email') + " : {{ EVENT.CONTACT_EMAIL }}", value: "{{ EVENT.CONTACT_EMAIL }}" },
  { label: _('event_end_if_has_documents') + " : {{ EVENT.END_IF_HAS_DOCUMENTS }}", value: "{{ EVENT.END_IF_HAS_DOCUMENTS }}" },
  { label: _('event_program_url') + " : {{ EVENT.PROGRAM_URL }}", value: "{{ EVENT.PROGRAM_URL }}" },
  { label: _('event_inscription_url') + " : {{ EVENT.INSCRIPTION_URL }}", value: "{{ EVENT.INSCRIPTION_URL }}" },
  { label: _('event_payment_url') + " : {{ EVENT.PAYMENT_URL }}", value: "{{ EVENT.PAYMENT_URL }}" },
  { label: _('event_documents_url') + " : {{ EVENT.DOCUMENTS_URL }}", value: "{{ EVENT.DOCUMENTS_URL }}" },
  { label: _('event_start_if_has_documents') + " : {{ EVENT.START_IF_HAS_DOCUMENTS }}", value: "{{ EVENT.START_IF_HAS_DOCUMENTS }}" },
  { label: _('event_evaluation_url') + " : {{ EVENT.EVALUATION_URL }}", value: "{{ EVENT.EVALUATION_URL }}" },
  { label: _('event_certificate_url') + " : {{ EVENT.CERTIFICATE_URL }}", value: "{{ EVENT.CERTIFICATE_URL }}" },
  { label: _('organizer_iban') + " : {{ ORGANIZER.IBAN }}", value: "{{ ORGANIZER.IBAN }}" },
  { label: _('organizer_bic') + " : {{ ORGANIZER.BIC }}", value: "{{ ORGANIZER.BIC }}" },
  { label: _('organizer_name') + " : {{ ORGANIZER.NAME }}", value: "{{ ORGANIZER.NAME }}" },
  { label: _('recipient_first_name') + " : {{ RECIPIENT.FN }}", value: "{{ RECIPIENT.FN }}" },
  { label: _('recipient_last_name') + " : {{ RECIPIENT.LN }}", value: "{{ RECIPIENT.LN }}" },
  { label: _('recipient_email') + " : {{ RECIPIENT.EMAIL }}", value: "{{ RECIPIENT.EMAIL }}" },
  { label: _('recipient_plan') + " : {{ RECIPIENT.PLAN }}", value: "{{ RECIPIENT.PLAN }}" },
  { label: _('recipient_program') + " : {{ RECIPIENT.PROGRAM }}", value: "{{ RECIPIENT.PROGRAM }}" },
  { label: _('certificate_comment') + " : {{ CERTIFICATE.COMMENT }}", value: "{{ CERTIFICATE.COMMENT }}" },
  { label: _('certificate_hours') + " : {{ CERTIFICATE.HOURS }}", value: "{{ CERTIFICATE.HOURS }}" },
  { label: _('partner_guest_coupon') + " : {{ PARTNER_GUEST.COUPON }}", value: "{{ PARTNER_GUEST.COUPON }}" },
  { label: _('partner_name') + " : {{ PARTNER.NAME }}", value: "{{ PARTNER.NAME }}" },
  { label: _('coupon_model_name') + " : {{ COUPON_MODEL.NAME }}", value: "{{ COUPON_MODEL.NAME }}" },
  { label: _('coupon_model_comment') + " : {{ COUPON_MODEL.COMMENT }}", value: "{{ COUPON_MODEL.COMMENT }}" },
  { label: _('cycle_id') + " : {{ CYCLE.ID }}", value: "{{ CYCLE.ID }}" },
  { label: _('cycle_name') + " : {{ CYCLE.NAME }}", value: "{{ CYCLE.NAME }}" },
  { label: _('cycle_contact_email') + " : {{ CYCLE.CONTACT_EMAIL }}", value: "{{ CYCLE.CONTACT_EMAIL }}" },
  { label: _('cycle_home_url') + " : {{ CYCLE.HOME_URL }}", value: "{{ CYCLE.HOME_URL }}" },
  { label: _('cycle_program_url') + " : {{ CYCLE.PROGRAM_URL }}", value: "{{ CYCLE.PROGRAM_URL }}" },
  { label: _('cycle_inscription_url') + " : {{ CYCLE.INSCRIPTION_URL }}", value: "{{ CYCLE.INSCRIPTION_URL }}" },
  { label: _('cycle_payment_url') + " : {{ CYCLE.PAYMENT_URL }}", value: "{{ CYCLE.PAYMENT_URL }}" },
  { label: _('cycle_documents_url') + " : {{ CYCLE.DOCUMENTS_URL }}", value: "{{ CYCLE.DOCUMENTS_URL }}" },
  { label: _('invoice_credit_note_url') + " : {{ INVOICE.CREDIT_NOTE_URL }}", value: "{{ INVOICE.CREDIT_NOTE_URL }}" },
  { label: _('survey_title') + " : {{ SURVEY.TITLE }}", value: "{{ SURVEY.TITLE }}" },
  { label: _('survey_url') + " : {{ SURVEY.URL }}", value: "{{ SURVEY.URL }}" },
];

export const RECIPIENT_TYPE = "RECIPIENT";
export const EVENT_TYPE = "EVENT";
export const WTB_TYPE = "WTB";
